import React, { useState, useEffect, useCallback } from "react";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import {
  getProductPromocodes,
  activateDeactivateProductPromocode,
} from "../../apis";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import actions from "../../actions";
import AddEdit from "./AddEdit";

const ProductPromocodes = ({
  logoutRequest,
  startLoadingRequest,
  stopLoadingRequest,
}) => {
  const [data, setData] = useState([]);
  const { pathname, search } = useLocation();
  const fetch = useCallback(async () => {
    try {
      const apiResponse = await getProductPromocodes(search);
      if (apiResponse?.data?.data?.productPromocodes?.length > 0) {
        setData([...apiResponse?.data?.data?.productPromocodes]);
      } else {
        setData([]);
      }
      stopLoadingRequest();
    } catch (error) {
      if (error?.response?.status === 401) {
        toast.error(error.response.data.message);
        logoutRequest();
      } else if (error?.response?.status === 403) {
        toast.error(error.response.data.message);
      }
      stopLoadingRequest();
    }
  }, [data, pathname, search]);

  useEffect(() => {
    startLoadingRequest();
    fetch();
  }, [pathname, search]);
  const activateDeactivateProductPromocodeHandler = useCallback(
    async (id, requestType) => {
      try {
        startLoadingRequest();
        await activateDeactivateProductPromocode(id, { requestType });
        fetch();
        toast.success(
          requestType === 1
            ? "Promocode activated successfully"
            : "Promocode deactivated successfully"
        );
      } catch (error) {
        if (error?.response?.status === 401) {
          toast.error(error.response.data.message);
          logoutRequest();
        } else if (error?.response?.status === 403) {
          toast.error(error.response.data.message);
        }
        stopLoadingRequest();
      }
    },
    []
  );
  return (
    <div className="section">
      <h1>Product Promocodes</h1>
      <div className="wrapper">
        <div className="table-wrapper">
          <div className="table-header">
            <AddEdit fetch={fetch} />
          </div>
          <div className="table-container">
            <table>
              <thead>
                <th>#</th>
                <th>Created at</th>
                <th>Description</th>
                <th>Active/Inactive</th>
                <th>Actions</th>
              </thead>
              <tbody>
                {data?.map((item, index) => (
                  <tr key={item.id}>
                    <td>{index + 1}</td>
                    <td>{item.createdAt}</td>
                    <td>{item.description}</td>
                    <td>{item.isActive ? "Active" : "Inactive"}</td>
                    <td>
                      <div className="table-actions">
                        {item?.isActive ? (
                          <>
                            <AddEdit data={item} fetch={fetch} />
                            <button
                              className="danger"
                              onClick={() =>
                                activateDeactivateProductPromocodeHandler(
                                  item.id,
                                  0
                                )
                              }
                            >
                              Deactivate
                            </button>
                          </>
                        ) : (
                          <button
                            onClick={() =>
                              activateDeactivateProductPromocodeHandler(
                                item.id,
                                1
                              )
                            }
                            className="success"
                          >
                            Activate
                          </button>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      logoutRequest: actions.auth.logout,
      stopLoadingRequest: actions.loader.stopLoading,
      startLoadingRequest: actions.loader.startLoading,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(ProductPromocodes);
