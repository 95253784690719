import React, { useCallback, useState, useEffect } from "react";
import { IoClose } from "react-icons/io5";
import { toast } from "react-toastify";
import SelectField from "../../components/Select/Select";
import { getPermissions, createAdmin, updateAdmin } from "../../apis";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import actions from "../../actions";

const AddEdit = ({
  fetch,
  data,
  logoutRequest,
  startLoadingRequest,
  stopLoadingRequest,
}) => {
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({
    firstName: data?.firstName || "",
    lastName: data?.lastName || "",
    email: data?.email || "",
    permissions:
      data?.permissions?.map((el) => ({
        value: el.id,
        label: el.name,
      })) || [],
    gender: { value: data?.gender || "", label: data?.gender || "" },
    password: "",
  });

  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    permissions: "",
    gender: "",
    password: "",
  });

  const [permissions, setPermissions] = useState([]);

  const openHandler = useCallback(() => {
    setOpen(!open);
  }, [open]);

  const changeHandler = useCallback(
    (e) => {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    },
    [formData]
  );
  const selectDropdownChangeHandler = useCallback(
    (name, value) => {
      setFormData({
        ...formData,
        [name]: value,
      });
    },
    [formData]
  );

  const fetchPermissions = useCallback(async () => {
    try {
      const apiResponse = await getPermissions();
      if (apiResponse?.data?.data?.permissions?.length > 0) {
        setPermissions([
          ...apiResponse.data.data.permissions.map((el) => ({
            value: el.id,
            label: el.name,
          })),
        ]);
      } else {
        setPermissions([]);
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        toast.error(error.response.data.message);
        logoutRequest();
      } else if (error?.response?.status === 403) {
        toast.error(error.response.data.message);
      }
    }
  }, []);

  useEffect(() => {
    if (open) {
      setFormData({
        firstName: data?.firstName || "",
        lastName: data?.lastName || "",
        email: data?.email || "",
        permissions:
          data?.permissions?.map((el) => ({
            value: el.id,
            label: el.name,
          })) || [],
        gender: { value: data?.gender || "", label: data?.gender || "" },
        password: "",
      });
      fetchPermissions();
    }
  }, [open]);

  const submitHandler = useCallback(async () => {
    try {
      startLoadingRequest();
      let cleanData = {
        firstName: formData?.firstName?.trim(),
        lastName: formData?.lastName?.trim(),
        email: formData?.email?.trim(),
        permissions:
          formData?.permissions
            ?.filter((el) => el.value?.trim() !== "")
            ?.map((el) => el.value) || [],
        gender: formData?.gender?.value,
        ...(!data?.id && { password: formData?.password?.trim() }),
      };
      let tempErrors = {};
      if (!cleanData.firstName) {
        tempErrors["firstName"] = "First name is required";
      }
      if (!cleanData.lastName) {
        tempErrors["lastName"] = "Last name is required";
      }
      if (!data?.id && cleanData.password?.length < 8) {
        tempErrors["password"] = "Password must have 8 characters";
      }
      if (!cleanData.email) {
        tempErrors["email"] = "Email is required";
      }
      if (!cleanData.gender) {
        tempErrors["gender"] = "Gender is required";
      }
      if (cleanData.permissions?.length < 1) {
        tempErrors["permissions"] = "Permissions is required";
      }
      if (Object.keys(tempErrors)?.length > 0) {
        setErrors(tempErrors);
        stopLoadingRequest();
        return;
      } else {
        setErrors({});
      }
      if (data?.id) {
        await updateAdmin(data?.id, cleanData);
      } else {
        await createAdmin(cleanData);
      }
      setOpen(false);
      setFormData({
        firstName: "",
        lastName: "",
        email: "",
        permissions: [{ value: "", label: "" }],
        gender: { value: "", label: "" },
        password: "",
      });
      setErrors({
        firstName: "",
        lastName: "",
        email: "",
        permissions: "",
        gender: "",
        password: "",
      });
      fetch();
    } catch (error) {
      stopLoadingRequest();
      toast.error(error?.response?.data?.message);
      if (error?.response?.status === 401) {
        logoutRequest();
      }
    }
  }, [formData, data]);

  useEffect(() => {
    if (data?.id) {
      setFormData({
        firstName: data?.firstName || "",
        lastName: data?.lastName || "",
        email: data?.email || "",
        permissions:
          data?.permissions?.map((el) => ({
            value: el.id,
            label: el.name,
          })) || [],
        gender: { value: data?.gender || "", label: data?.gender || "" },
      });
    } else {
      setFormData({
        firstName: "",
        lastName: "",
        email: "",
        permissions: "",
        gender: { value: "", label: "" },
        password: "",
      });
    }
  }, [data?.id]);

  return (
    <div className="modal-container">
      <div className="add-modal">
        <button
          className={data?.id ? "secondary" : "add"}
          onClick={openHandler}
        >
          {data?.id ? "Edit" : "Add"}
        </button>
      </div>
      <div className={open ? "modal active" : "modal"}>
        <div className="wrapper">
          <div className="header">
            <h2>{data?.id ? "Edit Admin" : "Add Admin"}</h2>
            <button type="button" className="close" onClick={openHandler}>
              <IoClose size={18} />
            </button>
          </div>
          <div className="content">
            <div className="form-section">
              <div className="input-wrapper">
                <label>First Name</label>
                <input
                  name="firstName"
                  value={formData.firstName}
                  onChange={changeHandler}
                />
                {errors.firstName && <p>{errors.firstName}</p>}
              </div>
              <div className="input-wrapper">
                <label>Last Name</label>
                <input
                  name="lastName"
                  value={formData.lastName}
                  onChange={changeHandler}
                />
                {errors.lastName && <p>{errors.lastName}</p>}
              </div>
              <div className="input-wrapper">
                <label>Email</label>
                <input
                  name="email"
                  value={formData.email}
                  onChange={changeHandler}
                />
                {errors.email && <p>{errors.email}</p>}
              </div>
              {!data?.id && (
                <div className="input-wrapper">
                  <label>Password</label>
                  <input
                    name="password"
                    value={formData.password}
                    onChange={changeHandler}
                  />
                  {errors.password && <p>{errors.password}</p>}
                </div>
              )}
              <div className="input-wrapper">
                <label>Gender</label>
                <SelectField
                  name="gender"
                  onChange={selectDropdownChangeHandler}
                  value={formData.gender}
                  isMulti={false}
                  options={[
                    {
                      value: "Male",
                      label: "Male",
                    },
                    {
                      value: "Female",
                      label: "Female",
                    },
                    {
                      value: "Not Specified",
                      label: "Not Specified",
                    },
                  ]}
                />
                {errors.gender && <p>{errors.gender}</p>}
              </div>
              <div className="input-wrapper">
                <label>Permissions</label>
                <SelectField
                  name="permissions"
                  onChange={selectDropdownChangeHandler}
                  value={formData.permissions}
                  isMulti={true}
                  options={permissions}
                />
                {errors.permissions && <p>{errors.permissions}</p>}
              </div>
            </div>
          </div>
          <div className="footer">
            <button type="button" onClick={submitHandler} className="primary">
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      logoutRequest: actions.auth.logout,
      startLoadingRequest: actions.loader.startLoading,
      stopLoadingRequest: actions.loader.stopLoading,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(AddEdit);
