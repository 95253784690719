import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { FaRegUser } from "react-icons/fa/index.esm";
import { FaKey } from "react-icons/fa/index.esm";
import { FaEye } from "react-icons/fa/index.esm";
import { login } from "../../apis";
import { useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import actions from "../../actions";

const Login = ({ loginRequest, stopLoadingRequest, startLoadingRequest }) => {
  const navigate = useNavigate();

  const initialState = {
    email: "",
    password: "",
  };

  const [formData, setFormData] = useState(initialState);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("authorization")) {
      navigate("/dashboard?requestType=Today");
    } else {
      stopLoadingRequest();
    }
  }, []);

  const changeHandler = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    startLoadingRequest();
    try {
      let apiResponse = await login(formData);
      if (apiResponse?.data?.data?.accessToken) {
        localStorage.setItem(
          "authorization",
          apiResponse.data.data.accessToken
        );
        loginRequest(apiResponse?.data?.data?.adminDetails);
        stopLoadingRequest();
        navigate("/dashboard?requestType=Today");
      }
      setFormData(initialState);
    } catch (error) {
      stopLoadingRequest();
      toast.error(error.response?.data.message);
    }
  };

  return (
    <div className="login-container">
      <form className="login-wrapper">
        <div className="header">
          <div className="logo">
            <img src="/images/logo.png" />
          </div>
        </div>
        <div className="content">
          <div className="input-wrapper">
            <label>Email</label>
            <div className="icon-wrapper">
              <div className="icon">
                <FaRegUser size={14} />
              </div>
              <input
                name="email"
                onChange={changeHandler}
                value={formData.email}
                placeholder="email"
              />
            </div>
          </div>
          <div className="input-wrapper">
            <label>Password</label>
            <div className="icon-wrapper">
              <div className="icon">
                <FaKey size={13} />
              </div>
              <input
                type={showPassword ? "text" : "password"}
                name="password"
                onChange={changeHandler}
                value={formData.password}
                placeholder="password"
              />
              <div
                onClick={() => setShowPassword(!showPassword)}
                className={showPassword ? "icon active" : "icon"}
              >
                <FaEye size={16} />
              </div>
            </div>
          </div>
        </div>
        <div className="footer">
          <button onClick={submitHandler} type="button" className="primary">
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      loginRequest: actions.auth.login,
      stopLoadingRequest: actions.loader.stopLoading,
      startLoadingRequest: actions.loader.startLoading,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(Login);
