import React, { useState, useEffect, useCallback } from "react";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import { getBanners, deleteBanner, makeBannerLive } from "../../apis";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import actions from "../../actions";
import Pagination from "../../components/Pagination/Pagination";
import AddEdit from "./AddEdit";

const Admins = ({ logoutRequest, startLoadingRequest, stopLoadingRequest }) => {
  const [data, setData] = useState({});
  const { pathname, search } = useLocation();
  const fetch = useCallback(async () => {
    try {
      const apiResponse = await getBanners(search);
      if (apiResponse?.data?.data?.banners?.length > 0) {
        setData({ ...apiResponse?.data?.data });
      } else {
        setData({});
      }
      stopLoadingRequest();
    } catch (error) {
      if (error?.response?.status === 401) {
        toast.error(error.response.data.message);
        logoutRequest();
      } else if (error?.response?.status === 403) {
        toast.error(error.response.data.message);
      }
      stopLoadingRequest();
    }
  }, [data, search, pathname]);

  useEffect(() => {
    startLoadingRequest();
    fetch();
  }, [pathname, search]);
  const activateDeactivateBannerHandler = useCallback(async (id, isActive) => {
    try {
      startLoadingRequest();
      await deleteBanner(id);
      toast.success(
        !isActive
          ? "Banner activated successfully"
          : "Banner deactivated successfully"
      );
      fetch();
    } catch (error) {
      if (error?.response?.status === 401) {
        toast.error(error.response.data.message);
        logoutRequest();
      } else if (error?.response?.status === 403) {
        toast.error(error.response.data.message);
      }
      stopLoadingRequest();
    }
  }, []);
  const makeBannerLiveHandler = useCallback(async (id) => {
    try {
      startLoadingRequest();
      await makeBannerLive(id);
      toast.success("Banner is now live");
      fetch();
    } catch (error) {
      toast.error(error.response.data.message);
      if (error?.response?.status === 401) {
        toast.error(error.response.data.message);
        logoutRequest();
      }
      stopLoadingRequest();
    }
  }, []);
  return (
    <div className="section">
      <h1>Banners</h1>
      <div className="wrapper">
        <div className="table-wrapper">
          <div className="table-header">
            <AddEdit type={"banner-add"} fetch={fetch} />
          </div>
          <div className="table-container">
            <table>
              <thead>
                <th>#</th>
                <th>Image</th>
                <th>Code</th>
                <th>Title</th>
                <th>Caption</th>
                <th>Type</th>
                {/* <th>Live status</th>
                <th>Go live time</th>
                <th>Last action</th>
                <th>Last action time</th>
                <th>Last operation</th>
                <th>Last operation time</th> */}
                <th>Active/Inactive</th>
                <th>Created at</th>
                {/* <th>Last updated at</th> */}
                <th>Actions</th>
              </thead>
              <tbody>
                {data?.banners?.map((item, index) => (
                  <tr key={item.id}>
                    <td>{index + 1}</td>
                    <td className="rect-image">
                      {item?.document?.url && <img src={item.document.url} />}
                    </td>
                    <td>{item.code}</td>
                    <td>{item.title}</td>
                    <td>{item.caption}</td>
                    <td>{item.type}</td>
                    {/* <td>{item.isLive ? "Live" : "Not Live"}</td>
                    <td>{item.goLiveDateTime}</td>
                    <td>{item.action?.description}</td>
                    <td>{item.action?.dateTime}</td>
                    <td>{item.operation?.type}</td>
                    <td>{item.operation?.dateTime}</td> */}
                    <td>{item.isActive ? "Active" : "Inactive"}</td>
                    <td>{item.createdAt}</td>
                    {/* <td>{item.updatedAt}</td> */}
                    <td>
                      <div className="table-actions">
                        {item?.isActive && (
                          <>
                            <AddEdit
                              type={`TnE-${item.id}`}
                              data={item}
                              fetch={fetch}
                            />
                            {/* {!item.isLive && (
                              <>
                                <button
                                  className="success"
                                  onClick={() =>
                                    makeTipAndTrickLiveHandler(item.id)
                                  }
                                >
                                  Publish
                                </button>
                              </>
                            )} */}
                          </>
                        )}
                        <button
                          className={item.isActive ? "danger" : "success"}
                          onClick={() =>
                            activateDeactivateBannerHandler(
                              item.id,
                              item.isActive
                            )
                          }
                        >
                          {item.isActive ? "Deactivate" : "Activate"}
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <Pagination pageInfo={data.pageInfo} />
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      logoutRequest: actions.auth.logout,
      stopLoadingRequest: actions.loader.stopLoading,
      startLoadingRequest: actions.loader.startLoading,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(Admins);
